import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserManagementService } from '../user-management.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(private fb: FormBuilder,private userService: UserManagementService,) {}

  ngOnInit() {
    this.signupForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password')?.value === g.get('confirmPassword')?.value
      ? null : { 'mismatch': true };
  }

  getErrorMessage(controlName: string): string {
    const control = this.signupForm.get(controlName);
    if (control?.hasError('required')) {
      return `${controlName.charAt(0).toUpperCase() + controlName.slice(1)} is required`;
    }
    if (control?.hasError('email')) {
      return 'Invalid email address';
    }
    if (control?.hasError('minlength')) {
      return `Minimum length is ${control.errors?.['minlength'].requiredLength} characters`;
    }
    if (control?.hasError('pattern')) {
      return 'Please enter a valid 10-digit phone number';
    }
    if (controlName === 'confirmPassword' && this.signupForm.hasError('mismatch')) {
      return 'Passwords do not match';
    }
    return '';
  }

  onSubmit() {
    if (this.signupForm.valid) {
      console.log(this.signupForm.value);
      let request={
          username:this.signupForm.value.username,
          firstName:this.signupForm.value.firstName,
          lastName:this.signupForm.value.lastName,
          password:this.signupForm.value.password,
          email:this.signupForm.value.email,
          phone_number:this.signupForm.value.phone_number
        }
        this.userService.signup(request).then((response: any) => {
          if (response && response.status === 1) {
            this.showAlert('Registered Successfully.Admin will contact you');
          }
      })

    } else {
      Object.keys(this.signupForm.controls).forEach(key => {
        const control = this.signupForm.get(key);
        if (control?.invalid) {
          control.markAsTouched();
        }
      });
    }
  }
  showAlert(msg) {
    const swalContent = {
      title: msg,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      //showDenyButton: true,
      showCancelButton: true,
      //confirmButtonText: `Next`,
      //cancelButtonText: `Preview`,
      confirmButtonColor: '#0DB1E4',
      cancelButtonColor: '#0DB1E4',
    };
    // Swal.fire(swalContent).then((result: any) => {
    //   if (result.isDismissed) {
    //     this.showPreviewDashboard = true;
    //   }
      // const siteId = this.dashboardForm.value.site_id
      // this.dashboardForm.reset();
      // this.dashboardForm.controls.site_id.setValue(siteId);
    //});
  }
}