import { Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management/user-management.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-corporate-admin-nav',
  templateUrl: './corporate-admin-nav.component.html',
  styleUrls: ['./corporate-admin-nav.component.css']
})
export class CorporateAdminNavComponent implements OnInit {
  public isCollapsed = false;
  public isMenuCollapsed = true;
  sitename: string;
  userRole: any;
  userDetail: any;
  isNavigating: boolean = false;

  constructor(
    private userService: UserManagementService,
    private router: Router
  ) {
    this.userDetail = this.userService.getUserDetail();
    this.userRole = this.userDetail.role;
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getSiteName();
      }
    });
  }

  logout() {
    this.userService.logout();
  }

  getSiteName() {
    this.sitename = this.userService.getCurrentSitename();
  }

  setSiteName() {
    this.userService.setCurrentSitename('');
  }

  navigateToType(type: any) {
    if (this.isNavigating) return;
  
    this.isNavigating = true; 
    this.router.navigate(['/mixdashboard/mix-report-list', type])
      .finally(() => {
        this.isNavigating = false; 
      });
  }
  
  
  
}