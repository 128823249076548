import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnderdevelopmentComponent } from './components/underdevelopment/underdevelopment.component';
import { MainPageComponent } from './main-page/main-page.component';
import { RouteChildGuard } from './route-child.guard';
import { RouteGuard } from './route.guard';
import { LoginComponent } from './user-management/login/login.component';
import { SignupComponent } from './user-management/signup/signup.component'
import { PeriodicReportComponent } from './periodic-report/periodic-report.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [RouteGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [RouteGuard] },
  {
    path: '', component: MainPageComponent, children: [
      { path: '', loadChildren: () => import('./components/components.module')
      .then(m => m.ComponentsModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]},
      { path: 'category', loadChildren: () => import('./category/category.module')
      .then(m => m.CategoryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard] },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module')
      .then(m => m.DashboardModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard] },
      { path: 'site', loadChildren: () => import('./site/site.module')
      .then(m => m.SiteModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard] },
      {
        path: 'categoryadminlib', loadChildren: () => import('./category-admin-library/category-admin-library.module')
          .then(m => m.CategoryAdminLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'opexmodule', loadChildren: () => import('./opex-module-library/opex-module-library.module')
          .then(m => m.OpexModuleLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'useradminlib', loadChildren: () => import('./user-admin/user-admin.module')
          .then(m => m.UserAdminModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'uploadslib', loadChildren: () => import('./system-admin-uploads/system-admin-uploads.module')
          .then(m => m.SystemAdminUploadsModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'systemadminlibrary', loadChildren: () => import('./generic-library/generic-library.module')
          .then(m => m.GenericLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'corporation', loadChildren: () => import('./corporation-admin-library/corporation-admin-library.module')
          .then(m => m.CorporationAdminLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'mixdashboard', loadChildren: () => import('./site-mixed-dashboard/site-mixed-dashboard.module')
          .then(m => m.SiteMixedDashboardModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'parameteradmin', loadChildren: () => import('./parameter-admin-library/parameter-admin-library.module')
          .then(m => m.ParameterAdminLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'moduleadmin', loadChildren: () => import('./module-admin-library/module-admin-library.module').
          then(m => m.ModuleAdminLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'userrolelibrary', loadChildren: () => import('./user-role-library/user-role-library.module')
          .then(m => m.UserRoleLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'reportlibrary', loadChildren: () => import('./report-module-library/report-module-library.module')
          .then(m => m.ReportModuleLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'uploadagent', loadChildren: () => import('./upload-agent/upload-agent.module')
          .then(m => m.UploadAgentModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'alerts', loadChildren: () => import('./alert-module-library/alert-module-library.module')
          .then(m => m.AlertModuleLibraryModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {
        path: 'equipment', loadChildren: () => import('./equipment/equipment.module')
          .then(m => m.EquipmentModule), canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
      },
      {path: 'safety/addmodule', component: UnderdevelopmentComponent, canActivate: [RouteGuard]},
      {path: 'safety/editmodule', component: UnderdevelopmentComponent, canActivate: [RouteGuard]},
      {path: 'equipment/addmodule', component: UnderdevelopmentComponent, canActivate: [RouteGuard]},
      {path: 'equipment/editmodule', component: UnderdevelopmentComponent, canActivate: [RouteGuard]},
      {path: 'periodicreport', component: PeriodicReportComponent, canActivate: [RouteGuard]},
    ], canActivate: [RouteGuard], canActivateChild: [RouteChildGuard]
  },

  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
