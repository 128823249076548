<!-- signup.component.html -->
<div class="signup-container">
  <div class="signup-card">
    <h2>Create User</h2>
    <p class="subtitle">Please fill in your information to get started</p>

    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="signup-form">
      <div class="form-field">
        <label for="username">Username</label>
        <input 
          id="username"
          type="text"
          formControlName="username"
          class="form-control"
          [class.is-invalid]="signupForm.get('username')?.invalid && signupForm.get('username')?.touched"
          placeholder="Enter username">
        <div class="error-message" *ngIf="signupForm.get('username')?.invalid && signupForm.get('username')?.touched">
          {{ getErrorMessage('username') }}
        </div>
      </div>

      <div class="name-row">
        <div class="form-field">
          <label for="firstName">First Name</label>
          <input 
            id="firstName"
            type="text"
            formControlName="firstName"
            class="form-control"
            [class.is-invalid]="signupForm.get('firstName')?.invalid && signupForm.get('firstName')?.touched"
            placeholder="Enter first name">
          <div class="error-message" *ngIf="signupForm.get('firstName')?.invalid && signupForm.get('firstName')?.touched">
            {{ getErrorMessage('firstName') }}
          </div>
        </div>

        <div class="form-field">
          <label for="lastName">Last Name</label>
          <input 
            id="lastName"
            type="text"
            formControlName="lastName"
            class="form-control"
            [class.is-invalid]="signupForm.get('lastName')?.invalid && signupForm.get('lastName')?.touched"
            placeholder="Enter last name">
          <div class="error-message" *ngIf="signupForm.get('lastName')?.invalid && signupForm.get('lastName')?.touched">
            {{ getErrorMessage('lastName') }}
          </div>
        </div>
      </div>

      <div class="form-field">
        <label for="email">Email Address</label>
        <input 
          id="email"
          type="email"
          formControlName="email"
          class="form-control"
          [class.is-invalid]="signupForm.get('email')?.invalid && signupForm.get('email')?.touched"
          placeholder="Enter email address">
        <div class="error-message" *ngIf="signupForm.get('email')?.invalid && signupForm.get('email')?.touched">
          {{ getErrorMessage('email') }}
        </div>
      </div>

      <div class="form-field">
        <label for="phone_number">Phone Number</label>
        <input 
          id="phone_number"
          type="tel"
          formControlName="phone_number"
          class="form-control"
          [class.is-invalid]="signupForm.get('phone_number')?.invalid && signupForm.get('phone_number')?.touched"
          placeholder="Enter phone number">
        <div class="error-message" *ngIf="signupForm.get('phone_number')?.invalid && signupForm.get('phone_number')?.touched">
          {{ getErrorMessage('phone_number') }}
        </div>
      </div>

      <div class="form-field">
        <label for="password">Password</label>
        <div class="password-input">
          <input 
            id="password"
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            class="form-control"
            [class.is-invalid]="signupForm.get('password')?.invalid && signupForm.get('password')?.touched"
            placeholder="Enter password">
          <i class="password-toggle" (click)="hidePassword = !hidePassword">
            <span class="material-icons">{{ hidePassword ? 'visibility_off' : 'visibility' }}</span>
          </i>
        </div>
        <div class="error-message" *ngIf="signupForm.get('password')?.invalid && signupForm.get('password')?.touched">
          {{ getErrorMessage('password') }}
        </div>
      </div>

      <div class="form-field">
        <label for="confirmPassword">Confirm Password</label>
        <div class="password-input">
          <input 
            id="confirmPassword"
            [type]="hideConfirmPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            class="form-control"
            [class.is-invalid]="signupForm.get('confirmPassword')?.invalid && signupForm.get('confirmPassword')?.touched"
            placeholder="Confirm password">
          <i class="password-toggle" (click)="hideConfirmPassword = !hideConfirmPassword">
            <span class="material-icons">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</span>
          </i>
        </div>
        <div class="error-message" *ngIf="(signupForm.get('confirmPassword')?.invalid || signupForm.hasError('mismatch')) && signupForm.get('confirmPassword')?.touched">
          {{ getErrorMessage('confirmPassword') }}
        </div>
      </div>

      <button type="submit" class="btn-submit" [disabled]="signupForm.invalid">
        Create User
      </button>
    </form>
  </div>
</div>