<nav class="navbar navbar-expand-lg bg-custom boxshadow fixed-top">
  <a class="navbar-brand" routerLink="/">
    <span>
      <img
        class="logo"
        src="./assets/images/logo_new.png"
        (click)="setSiteName()" />
    </span>
    <span *ngIf="sitename">
      <img  alt="{{ sitename }}" class="logo" />
    </span>
  </a>

  <button class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <div
    id="wwtp"
    class="collapse navbar-collapse"
    [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav ml-auto" style="margin-top: 15px">
      <!-- Common for both Corp Admin and Corp User -->
      <a
        class="nav-link text-uppercase text-white"
        routerLink="/corporation/map-corp">
        <img src="./assets/images/map.jpg" alt="Map Icon" class="nav-icon1" />
        MAP
      </a>

      <a
        class="nav-link text-uppercase text-white"
        routerLink="/corporation/corporate-dashboard">
        <img
          src="./assets/images/score.jpg"
          alt="Plant Icon"
          class="nav-icon" />
        PLANT SCORE
      </a>

      <li ngbDropdown class="nav-item">
        <a
          class="nav-link text-uppercase text-white"
          ngbDropdownToggle
          id="systemAdmin"
          role="button">
          <img
            src="./assets/images/statistics.jpg"
            alt="Icon"
            class="nav-icon" />
          STATISTICS <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>

        <ul
          ngbDropdownMenu
          aria-labelledby="systemAdmin"
          class="dropdown-menu top-52px shadow user-menus border-none">
          <a
            ngbDropdownItem
            (click)="setSiteName()"
            routerLink="/corporation/category-statistics"
            >Category</a
          >
          <div class="dropdown-divider"></div>
          <a
            ngbDropdownItem
            (click)="setSiteName()"
            routerLink="/corporation/plant-statistics"
            >Plant</a
          >
        </ul>
      </li>

      <!-- Only visible to Corp Admin (userRole === 6) -->
      <li *ngIf="userRole == 6" class="nav-item" ngbDropdown>
        <a
          class="nav-link text-uppercase text-white"
          ngbDropdownToggle
          id="systemAdmin"
          role="button">
          <img
            src="./assets/images/settings.jpg"
            alt="Settings Icon"
            class="nav-icon" />
          SETTINGS <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>

        <ul
          ngbDropdownMenu
          aria-labelledby="systemAdmin"
          class="dropdown-menu top-52px shadow user-menus border-none">
          <a
            ngbDropdownItem
            (click)="setSiteName()"
            routerLink="/corporation/edit-corp"
            >Corp Setting</a
          >
          <div class="dropdown-divider"></div>
          <a
            ngbDropdownItem
            (click)="setSiteName()"
            routerLink="/corporation/manage-corp-user"
            >Corp User Setting</a
          >
          <!-- <div class="dropdown-divider"></div>
          <a
            ngbDropdownItem
            (click)="setSiteName()"
            routerLink="/corporation/user-role"
            >Corp User Role</a
          > -->
          <div class="dropdown-divider"></div>
          <a
            ngbDropdownItem
            (click)="setSiteName()"
            routerLink="/corporation/manage-corp-site"
            >Plant Setting</a
          >
          <div class="dropdown-divider"></div>
          <a
            ngbDropdownItem
            (click)="setSiteName()"
            routerLink="/corporation/categories-listing"
            >Categories</a
          >
          <div class="dropdown-divider"></div>
          <li ngbDropdown placement="bottom-right">
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>
              Statistics
              <i class="fa fa-angle-down float-down" aria-hidden="true"></i>
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="Dashboard"
              class="dropdown-menu shadow user-menus border-none"
              style="font-size: 14px">
              <a
                ngbDropdownItem (click)="navigateToType('category')"
                [class.disabled]="isNavigating" style="cursor: pointer">
                Category
              </a>

              <div class="dropdown-divider"></div>

              <a
                ngbDropdownItem (click)="navigateToType('plant')"
                [class.disabled]="isNavigating" style="cursor: pointer"> Plant
              </a>
            </div>
          </li>
        </ul>
      </li>
      <li class="nav-item ml-1" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="userauth" role="button">
          <img
            class=""
            src="./assets/images/avathar.jpg"
            height="40"
            style="border-radius: 50%; margin-top: -8px; height: 35px" />
        </a>
        <ul
          ngbDropdownMenu
          aria-labelledby="userauth"
          class="dropdown-menu bg-light top-52px logout shadow user-menus">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" (click)="logout()" ngbDropdownToggle
              ><i class="fa fa-power-off"></i> Logout
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>

<!-- <a ngbDropdownItem (click)="setSiteName()" routerLink="/corporation/corporate-reportlist-dashboard"> Corporate Dashboard</a> -->
<!-- <div class="dropdown-divider"></div> -->
<!-- <a ngbDropdownItem routerLink="/corporation/edit-corp-site"> Sites Settings</a> -->
<!-- <div class="dropdown-divider"></div> -->
<!-- <a ngbDropdownItem routerLink="/corporation/corporate-dashboard"> Corporate Dashboard</a> -->

<!-- <li class="nav-item" ngbDropdown>
        <a class="nav-link text-white text-uppercase" ngbDropdownToggle id="systemAdmin" role="button">
          SITE <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="systemAdmin"
          class="dropdown-menu bg-light top-52px shadow border-none user-menus">

          <li ngbDropdown placement="bottom-right">
            <a ngbDropdownItem routerLink="/corporation/edit-corp-site"> Sites Settings</a>
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Alerts Settings<i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="Dashboard"
              class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role">
              <a ngbDropdownItem routerLink="/alerts/pmalerts"> PM Alerts</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/alerts/modulealerts"> Module Alerts</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/alerts/parameteralerts"> Parameters Alerts</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/alerts/opexalerts"> Opex Alerts</a>
            </div>
            <div class="dropdown-divider"></div>
          </li>
          <li ngbDropdown placement="right">
            <a class="sub-dropdown" id="module" ngbDropdownToggle> Dashboards
              <i class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i>
            </a>-->
<!-- <div class="dropdown-divider"></div> -->
<!-- <div ngbDropdownMenu aria-labelledby="static"
              class="dropdown-menu bg-light user-menus  sub-dropdown-menu user_role">
          <li ngbDropdown placement="right">
            <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Process <i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i>
            </a>
            <div class="dropdown-divider"></div>
            <div ngbDropdownMenu aria-labelledby="analysis" class="dropdown-menu bg-white user-menus  sub-dropdown-menu"
              style="left: 0px!important;top: 66px;">
              <a ngbDropdownItem [routerLink]="['/reportlibrary/editreport', 'process']">Report Setting</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/site/addsitedashboard">Custom Dashboard</a>
            </div>
          </li>
          <li ngbDropdown placement="right">
            <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Opex <i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="analysis" class="dropdown-menu bg-white user-menus  sub-dropdown-menu"
              style="left: 0px!important;top: 31px;">
              <a ngbDropdownItem [routerLink]="['/reportlibrary/editreport', 'opex']">Report Setting</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/dashboard/addOpexDashboard">Custom Dashboard</a>
            </div>
          </li>
  </div>
  </li> -->
<!-- <li class="nav-item" ngbDropdown>
  <div class="dropdown-divider"></div>
  <a ngbDropdownItem routerLink="/equipment/equipmentsetting">Equipment Settings</a>
</li> -->
<!-- </ul>
  </li> -->
<!-- 
  <li class="nav-item" ngbDropdown>
    <a class="nav-link text-white" ngbDropdownToggle id="dashboard" role="button">
      DASHBOARD <i class="fa fa-angle-down"></i>
    </a>
    <ul ngbDropdownMenu aria-labelledby="dashboard"
      class="dropdown-menu bg-light top-52px  shadow user_role border-none user-menus">
      <li ngbDropdown placement="left">

        <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Process DB<i
            class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
        <div ngbDropdownMenu aria-labelledby="Dashboard"
          class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role">
          <a ngbDropdownItem (click)="setSiteName()" routerLink="/dashboard/processdashboard"> Default DB</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem (click)="setSiteName()" routerLink="/site/sitedashboard"> Custom DB</a>
        </div>
        <div class="dropdown-divider"></div>
        <div ngbDropdown placement="bottom-right">
          <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Opex Dashboard<i
              class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
          <div ngbDropdownMenu aria-labelledby="Dashboard"
            class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role">
            <a ngbDropdownItem (click)="setSiteName()" routerLink="/dashboard/opexdashboard"> Default DB</a>
            <div class="dropdown-divider"></div>
             <a ngbDropdownItem (click)="setSiteName()" routerLink="/dashboard/customeOpexDashboard"> Custom DB</a> -->
<!-- </div>
        </div> -->
<!-- <div class="dropdown-divider"></div>
        <div ngbDropdown placement="bottom-right">
          <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Corp Mix Dashboard<i
              class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
          <div ngbDropdownMenu aria-labelledby="Dashboard"
            class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role">
            <a ngbDropdownItem (click)="setSiteName()" routerLink="/corporation/corporate-site">Create Mix Dashboard</a>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem (click)="setSiteName()" routerLink="/corporation/corporate-site">All Corp Mix Dashboard</a>
          </div>
        </div>
        
       </li>
    </ul>
  </li> -->

<!-- <li class="nav-item" ngbDropdown>
    <a class="nav-link text-white" ngbDropdownToggle id="static" role="button">
      ALERTS <i class="fa fa-angle-down" aria-hidden="true"></i>
    </a>
    <ul ngbDropdownMenu aria-labelledby="static"
      class="dropdown-menu bg-light top-52px  shadow border-none user-menus static">
      <li ngbDropdown placement="left">
        <a class="sub-dropdown" (click)="setSiteName()" routerLink="/alerts/openalert" ngbDropdownItem>Open Alert</a>
        <div class="dropdown-divider"></div>
        <a class="sub-dropdown" (click)="setSiteName()" routerLink="/alerts/historyalert" ngbDropdownItem>History Alert</a>
      </li>
    </ul>
  </li>  -->
<!-- <li>
        <a class="nav-link"
          ><i class="fa fa-plus-square mt-10px" style="margin-top: -8px"></i>
        </a>
      </li>
      <li>
        <a class="nav-link">
          <img
            class=""
            src="./assets/images/logo-wwtp.png"
            height="40"
            style="margin-top: -8px"
        /></a>
      </li> -->
