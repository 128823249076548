import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { IpResponse } from '../models/mixed-report';
// import { tap } from 'rxjs/operators';
// import { OAuth2Client } from 'google-auth-library';

@Injectable({
  providedIn: 'root'
})

export class UserManagementService {

  private baseUrl = environment.baseUri;
  private nodeURL = environment.nodeUrl1;
  private config = environment.APT_KEY;
  private requestUri = environment.requestUri;
  private storage = environment.storage;
  private innerMessage = new Subject<any>();
  public selectedSite = null;
  public userDetails = [];
  public failCount = 0;

  constructor(private http: HttpClient, private router: Router) { 
   
  }

 
  login(obj) {
    return this.http.post(`${this.baseUrl}auth`, obj).toPromise();
  }
  signup(obj) {
    return this.http.post(`${this.nodeURL}signup`, obj).toPromise();
  }

  addUserDetail(user) {
    this.storage.setItem('user-info', JSON.stringify(user));
    this.failCount = 0;
    this.storage.setItem('falied-login-count', JSON.stringify(this.failCount));
  }

  addSiteDetails(sites) {
    this.storage.setItem('sites-info', JSON.stringify(sites));
    this.updateMessage({'purpose': 'siteadded', 'value': sites});
  }

  getUserDetail() {
    if (this.storage.getItem('user-info')) {
      return JSON.parse(this.storage.getItem('user-info'));
    } else {
      return null;
    }
  }

  getSiteDetail() {
    if (this.storage.getItem('sites-info')) {
      return JSON.parse(this.storage.getItem('sites-info'));
    } else {
      return null;
    }
  }

  getMessage(): Observable<any> {
    return this.innerMessage.asObservable();
  }
  updateMessage(message: any) {
    this.innerMessage.next(message);
  }

  setCurrentSite(pCurrentSite: string) {
    if (pCurrentSite){
      sessionStorage.setItem('current-site', pCurrentSite);
    } else {
      sessionStorage.removeItem('current-site');
    }
  }

  getCurrentSite() {
    return sessionStorage.getItem('current-site');
  }
  setCurrentSitename(pCurrentSitename: string) {
    if (pCurrentSitename){
      sessionStorage.setItem('current-sitename', pCurrentSitename);
    } else {
      sessionStorage.removeItem('current-sitename');
    }
  }

  getCurrentSitename() {
    return sessionStorage.getItem('current-sitename');
  }
  // removeCurrentSite() {
  //   sessionStorage.removeItem('current-site');
  // }
  setRemoveModule(request){
    this.storage.setItem('module-info', JSON.stringify(request));
  }
  
 
  getRemoveModule(){
    if (this.storage.getItem('module-info')) {
      return JSON.parse(this.storage.getItem('module-info'));
    }else{
      return null;
    }
  }

  getuserupdateInfo(obj) {
   return this.http.post(`${this.baseUrl}auth`, obj).toPromise();
  }

  logout() {

    //this.userDetails['userid']=JSON.parse(this.storage.getItem('user-info')).id;
    //this.userDetails['purpose']='userlogout';
    //const request = {'purpose': 'userlogout', 'userid': this.userDetails['userid']};
    //this.getuserupdateInfo(request);
    this.storage.removeItem('user-info');
    this.storage.removeItem('sites-info');
    this.storage.removeItem('module-info');
    sessionStorage.removeItem('current-site');
    localStorage.removeItem('completeModule');
    localStorage.removeItem('corporateModule');
    localStorage.removeItem('labModule');
    localStorage.removeItem('editModule');
    localStorage.removeItem('GROUP')
    this.selectedSite = null;
    this.router.navigate(['/login']);
  }

  glogin(obj) {
    return this.http.post(`${this.baseUrl}auth`, obj).toPromise();
  }

  getIPAddress1() {
    this.http.get('https://api.ipify.org/?format=json').subscribe((response: any) => {
      return response.ip;
    });
  }
  public getIPAddress(): Observable<IpResponse> {
    return this.http.get<IpResponse>('https://api.ipify.org?format=json');
  }
  
}
